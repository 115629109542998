import React, { useEffect, useState } from 'react';

import MainScrollBar from "./MainScrollBar";
import DroowMenu from "../components/header/menu/DroowMenu";
import LoadingPage from "../hooks/LoadingPage";
import axios from 'axios';
import { API_URL } from '../shared/constant';

function Layout(
    {className, children, tag: Tag = "div", activeScrollbar, ...restProps}) {
        const [isBusy, setBusy] = useState(false);
        const [menuContent, setMenuContent] = useState([
            {name: "Home", to: "/"},
            {name: "About", to: "/about"},
            {
                name: "Information",
                dropdownMenu: [{
                    name: 'Technical Specification', to: "/technical-specification"
                }, {
                    name: 'Packing Details', to: "/packing-details"
                }, {
                    name: 'Tiles Calculator', to: "/tiles-calculator"
                }]
            },
            {
                name: "Product",
                dropdownMenu: []
            },
            // {name: "Catalogue", to: "/catalogue"},
            {name: "Exports", to: "/exports"},
            // {name: "News", to: "/latest-news"},
            {name: "Contact", to: "/contact"},
        ]);

        useEffect(() => {
            localStorage.removeItem("sliders");
            axios.post(API_URL.SITE_CATEGORY_SUB_CATEGORY, {}).then(res => {
                if (res.data.data && res.data.data.length) {
                    let menus = menuContent;
                    menus[3].dropdownMenu = res.data.data.map((row) => {
                        if (row.sub_category && row.sub_category.length) {
                            let subMenu = {
                                name: row.name,
                                dropdownMenu: []
                            }
                            subMenu.dropdownMenu =  row.sub_category.map((subc) => {
                                return {name: subc.name, to: "/products/" + row.slug + "/" + subc.slug + "/collections"}
                            })
                            return subMenu;
                        } else {
                            return {name: row.name, to: "/products/" + row.slug}
                        }
                    });
                    setBusy(true);
                    setMenuContent(menus);
                }
            })
        }, []);

    return (
        <Tag id="main_layout" className={className}>
            { (isBusy && menuContent[3].dropdownMenu.length > 0) ? <DroowMenu menuContent={menuContent}/> : null }
            <LoadingPage/>
            {activeScrollbar ? <MainScrollBar  {...restProps}>{children}</MainScrollBar> : children}
        </Tag>
    );
}

export default Layout;